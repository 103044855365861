import { TableContainer, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import React, { forwardRef, useEffect, useReducer, useState } from "react";
import { ReactComponent as MenuDown } from "../../../assets/menu-down.svg";
import { highlightSelectedStyle } from "../../../constants";
import useColors from "../../../utils/useColors";
import { BASE_HEIGHT } from "../../../utils/widgetSizes";
import EmptyTableWithColumns from "../../common/EmptyTableWithColumns";
import EmptyTableWithoutColumns from "../../common/EmptyTableWithoutColumns";
import WidgetEditControls from "../../WidgetEditControls";
import { GET_DATA_SUBSCRIPTION } from "../../../queries";
import getColorOfRow from "../utils/getColorOfRow";

import { TableVirtuoso } from "react-virtuoso";
import ChartStaticTable from "./ChartStaticTable";
import ControlsStaticTable from "./ControlsStaticTable";
import { useApolloClient } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "#2780E3",
  },
  tableCellHead: {
    userSelect: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Medium",
  },

  tableCell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "none",
    fontSize: "16px",
    fontFamily: "Roboto-Regular",
  },
}));


const W_StaticTable = (props) => {
  const client = useApolloClient();
  const { objectProperties, selected, object, id } = props;

  const getPropValue = (prop) => objectProperties
    .find((obj) => obj.key === prop)
    ?.value;

  const { getColorBasedOnStyle } = useColors();

  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(
    getPropValue("settingsStyle"),
  );

  const defaultValues = {
    settingsColumns: getPropValue("settingsColumns"),
    valueValue: getPropValue("valueValue"),
    settingsShowTitle: getPropValue("settingsShowTitle"),
    settingsShowChart: getPropValue("settingsShowChart"),
    settingsShowRefreshButtons: getPropValue("settingsShowRefreshButtons"),
    chartChartType: getPropValue("chartChartType"),
    chartSmoothType: getPropValue("chartSmoothType"),
    chartLineWidth: getPropValue("chartLineWidth"),
    settingsChartSize: getPropValue("settingsChartSize"),
    settingsYaxisScale: getPropValue("settingsYaxisScale"),
    settingsMaximum: getPropValue("settingsMaximum"),
    settingsMinimum: getPropValue("settingsMinimum"),
    settingsObject: getPropValue("settingsObject"),
    settingsSchema: getPropValue("settingsSchema"),
    settingsDateRange: getPropValue("settingsDateRange"),
    settingsPeriod: getPropValue("settingsPeriod"),
    settingsWidgetControls: getPropValue("settingsWidgetControls"),
    settingsLinkedOnly: getPropValue("settingsLinkedOnly"),
  };

  const colors = [getPropValue("settingsStyle"), null];

  const [
    {
      valueValue,
      settingsColumns,
      settingsShowTitle,
      settingsShowChart,
      settingsShowRefreshButtons,
      chartChartType,
      chartSmoothType,
      chartLineWidth,
      settingsChartSize,
      settingsYaxisScale,
      settingsMaximum,
      settingsMinimum,
      settingsObject,
      settingsSchema,
      settingsDateRange,
      settingsPeriod,
      settingsWidgetControls,
      settingsLinkedOnly,
    },
    setValues,
  ] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const classes = useStyles();

  const getHeadName = (item) => {
    let title = "";

    if (item.alias) {
      return `${item.alias} (${item.mode})`;
    }

    title += item.value.title?.split("/")?.[1] || "n/a";

    if (item.mode) {
      title += ` (${item.mode})`;
    }

    return title;
  };

  const [orderByType, setOrderBy] = useState("desc");

  function fixedHeaderContent() {
    return (
      <TableRow
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        <TableCell
          onClick={() => {
            setOrderBy(orderByType === "desc" ? "asc" : "desc");
          }}
          className={classes.tableCellHead}
          style={{
            color: fgColor,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "38px",
              alignItems: "center",
            }}
          >
            <span>Date time</span>
            {orderByType === "desc" && (
              <MenuDown
                style={{
                  height: "20px",
                }}
              />
            )}
            {orderByType === "asc" && (
              <MenuDown
                style={{
                  transform: "rotate(180deg)",
                  height: "20px",
                }}
              />
            )}
          </div>
        </TableCell>
        {settingsColumns.columns.map((item) => (
          <TableCell
            align="right"
            className={classes.tableCellHead}
            style={{ color: fgColor }}
          >
            {getHeadName(item)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (_index, item) => {
    return (
      <>
        <TableCell
          component="th"
          scope="row"
          className={classes.tableCell}
          style={{ color: fgColor }}
        >
          {item["time"]}
        </TableCell>
        {settingsColumns.columns.map((column, index) => (
          <>
            <TableCell
              scope="row"
              className={classes.tableCell}
              style={{
                color: fgColor,
                textAlign: "right",
                width: "1px",
                whiteSpace: "nowrap",
              }}
            >
              {`${
                item.data.find((item) => {
                  const id = `${column.value.value}_${column.mode}`;
                  const idComing = `${item.propertyId}_${item.mode}`;
                  return id === idComing;
                })?.value
              }`}
            </TableCell>
          </>
        ))}
      </>
    );
  };

  const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} />
    )),
    Table: (props) => <Table {...props}
        size="small"
        className={classes.table}
        aria-label="simple table"
        style={{ backgroundColor: "transparent" }}
    />,
    TableHead: ({ ...props }) => (
      <TableHead
        {...props}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: bgColor,
          height: `${BASE_HEIGHT}px`,
        }}
      ></TableHead>
    ),
    TableRow: (props) => <TableRow  {...props} style={{
      height: `${BASE_HEIGHT}px`,
      backgroundColor: getColorOfRow(props["data-index"], colors),
    }} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      const key = data.Objects.relatedNode?.key;
      const value = data.Objects.relatedNode?.value;

      if (key) {
        setValues({ [key]: value });
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  return (
    <div
      className={"force-scroll"}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "100%",
        position: "relative",
        overflow: "auto",
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : "",
        borderRadius: "2px",
        textAlign: "center",
      }}
    >

      {settingsShowTitle && (
        <div style={{
          width: "100%",
          height: "48px",
          display: 'flex',
          flexShrink: 0,
          alignItems: 'center',
          color: fgColor,
          justifyContent: 'center',
        }}>
          <Typography variant="h5">
            {props.name}
          </Typography>
        </div>
      )}

      {settingsShowRefreshButtons && (
        <ControlsStaticTable
          settingsStyle={getPropValue("settingsStyle")}
          fgColor={fgColor}
          bgColor={bgColor}
          value={valueValue}
          settingsObject={settingsObject}
          settingsSchema={settingsSchema}
          settingsDateRange={settingsDateRange}
          settingsPeriod={settingsPeriod}
          settingsLinkedOnly={settingsLinkedOnly}
          settingsWidgetControls={settingsWidgetControls}
          columns={settingsColumns.columns}
          item={object}
        />
      )}

      {settingsShowChart && (
        <ChartStaticTable
          chartChartType={chartChartType}
          chartSmoothType={chartSmoothType}
          chartLineWidth={chartLineWidth}
          settingsTimeInterval={settingsPeriod}
          settingsChartSize={settingsChartSize}
          settingsYaxisScale={settingsYaxisScale}
          settingsMaximum={settingsMaximum}
          settingsMinimum={settingsMinimum}
          fgColor={fgColor}
          series={valueValue}
          columns={settingsColumns.columns}
        />
      )}

      {valueValue.length > 0 && settingsColumns.columns.length > 0 && (
        <div style={{ width: "100%", height: "100%" }}>
          <TableVirtuoso
            size="small"
            data={orderByType === "asc" ? valueValue : valueValue.map(item => item).reverse()}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </div>
      )}

      {settingsColumns.columns.length === 0 && valueValue.length === 0 && (
        <EmptyTableWithoutColumns fgColor={fgColor} />
      )}

      {settingsColumns.columns.length > 0 && valueValue.length === 0 && (
        <EmptyTableWithColumns
          fgColor={fgColor}
          infoText={
            'If you just added new columns you need to click "Update table" button'
          }
        />
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default React.memo(W_StaticTable);
